import React from 'react'

export default function Contact() {
    return (
        <div id='contact-container-1'>
            <div id='contact-container'>
                <form>
                    <h2>Send Me an Email! kieran98mueller@gmail.com</h2>
                    <hr />
                    <h2>Or call me at 612-508-9457</h2>
                </form>
            </div>
        </div>
    )
}
